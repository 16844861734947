import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NavigationPath} from "./core/enums/navigation-paths.enum";
import {authGuard} from "./core/guards/auth.guard";

const routes: Routes = [
  {
    path: NavigationPath.EMPTY_PATH,
    redirectTo: NavigationPath.LOGIN,
    pathMatch: 'full'
  },
  {
    path: NavigationPath.LOGIN,
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: NavigationPath.DASHBOARD,
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [authGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
